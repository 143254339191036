@import '@/styles/variables';

.search_place_wrapper {
  position: relative;
  width: 100%;

  // hide loader when in input less then minimum symbols to start search
  &.search_place_no_search {
    .search_place__loading-indicator {
      display: none;
    }
  }

  .search_place {
    &__container {
      width: 100%;
      max-width: 460px;
      box-shadow: unset;
    }

    &__control {
      border: $border-width-base solid $color-gray;
      border-radius: 32px;
      min-height: 40px;
      box-shadow: 0 3px 12px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.08);

      &:hover {
        border-color: $color-gray;
      }
    }

    &__value-container {
      display: inline-block;
      position: absolute;
      padding: 0;
      top: 0;
      bottom: 0;
      left: 40px;
      right: 38px;
    }

    &__input-container {
      width: 100%;
      display: inline-block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      padding: 0;
    }

    &__input,
    &__single-value {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &__single-value {
      display: flex;
      align-items: center;
    }

    &__placeholder {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      text-wrap: nowrap;
      overflow: hidden;
    }

    &__indicators,
    &__dropdown-indicator,
    &__indicator-separator {
      display: none;
    }

    &__menu {
      width: calc(100% - 2px);
      left: 1px;
      margin-top: $spacing-4;
      padding: $spacing-4;
      border-radius: 32px;
      box-shadow: 0 3px 12px 0 rgb(0 0 0 / 0.15);
      z-index: $z-index-header-dropdown;
    }

    &__menu-list {
      padding: $spacing-1;
      max-height: 320px;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        border: none;
        background: transparent;
        box-shadow: none;
      }

      &::-webkit-scrollbar-thumb {
        border: 6px solid $color-gray;
        border-radius: 8px;
        cursor: grab;

        &:hover {
          border-color: $color-gray-dark;
        }
      }
    }

    &__option {
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;
      background-color: unset;
      transition: background-color $transition-time $transition-variant;
      border-radius: 12px;

      &--is-focused {
        background-color: $color-gray;
      }

      &--is-selected,
      &--is-selected:hover {
        background-color: $color-green-main;
        color: $font-color-light;
      }

      &:not(:last-child) {
        margin-bottom: $spacing-2;
      }
    }
  }
}

.searchPlace__isMainPage {
  .search_place {
    &__control {
      padding: 32px 0;
      font-size: 17px;
      border-radius: 32px;
      border-color: $color-gray;
    }

    &__value-container {
      right: 100px;
    }
  }
}

.searchPlace__square {
  .search_place {
    &__control {
      padding: 24px 0;
      border-radius: $border-radius-base;
      border-color: $color-gray;
      box-shadow: unset;
    }

    &__control--is-focused {
      border-color: #417054;
      box-shadow: 0 0 6px 2px #417054;
    }

    &__menu {
      border-radius: $border-radius-base;
    }
  }
}
