@import "@/styles/variables";
@import "@/styles/mixins";

.single_value, .option_value {
  display: flex;
  width: 100%;
}

.single_sign {
  display: block;
  margin-right: $spacing-3;
}

.option_sign {
  margin-right: $spacing-3;
  text-align: center;
  flex: 1;
}


