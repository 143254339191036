@import "@/styles/mixins";

.single_value {
  display: none;
}

@include for-screen-from-1024 {
  .single_value {
    display: block;
    margin-left: 8px;
  }
}
